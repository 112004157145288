import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import PageHeader from '../../components/common/PageHeader';
import TwoColumnContent from '../../components/ContentBlades/TwoColumnContent';
import careerbanner from '../../assets/images/careerbanner.jpg';

const Career: FC = () => (
  <div className="w-full">
    <div className="container mx-auto ">
      <PageHeader
        title="We Are Hiring at GIGL!
"
        description="Join our team and be part of a dynamic, fast-growing organization that values innovation,
excellence, and teamwork. "
      />
      <div className="block lg:flex md:block lg:space-x-6 lg:p-6 md:p-6 p-3">
        <img
          src={careerbanner}
          alt=""
          className="lg:w-1/2 md:w-full w-full mb-1 lg:mb-0 h-auto  "
        />
        <div className="px-2 py-2 lg:py-2 md:py-2 lg:px-3 md:px-3">
          <p className="mb-5">
            <p className="font-bold mb-1 py-1">
              {' '}
              GIGL is looking for experienced and driven professionals to fill
              the following roles:
            </p>
            <ol>
              <li>• General Manager - Operations </li>
              <li> • Audit, Risk & Control Manager</li>
              <li> • General Manager - Business Services </li>
              <li> • Project Execution & Facilities Manager </li>
              <li> • Business Development Manager </li>
              <li> • Brand & Marketing Manager </li>
              <li> • Security, Safety, Health & Environment Officer </li>
              <li> • Head of Human Resources </li>
            </ol>
          </p>

          <p className="mb-5">
            <strong>Why Join GIGL</strong>
            <ol>
              <li>• Opportunity to work with industry leaders.</li>
              <li> • A culture that fosters growth and collaboration.</li>
              <li>• Competitive compensation and benefits.</li>
            </ol>
          </p>

          <p className="mb-5">
            <strong>Requirements</strong>
            <ol>
              <li>
                {' '}
                • Relevant qualifications and experience in the respective
                roles.
              </li>
              <li>
                {' '}
                • Strong leadership, analytical, and interpersonal skills.
              </li>
              <li> • A track record of excellence in previous roles. </li>
            </ol>
          </p>

          <p className="mb-5">
            <strong>What We Offer:</strong>
            <ol>
              <li>
                {' '}
                • Competitive Salary: A competitive salary and benefits package
              </li>
              <li>
                {' '}
                • Opportunities for Growth: Opportunities for career growth and
                professional development
              </li>
              <li>
                {' '}
                • Dynamic Work Enviornment: A dynamic and supportive work
                environment{' '}
              </li>
            </ol>
          </p>
          <p>
            <strong>Deadline:</strong> Applications close on February 15, 2025.
          </p>
          <p className="mb-5">
            If you are passionate about making an impact and driving success, we
            would love to hear from you!
          </p>
          <div className="my-4 w-full flex text-center lg:my-4 md:my-4">
            <a
              href="https://docs.google.com/forms/d/1E93DSvTgh_dZ67-5_RchzZbyrs3HyxA7E19wcZo3gSY/viewform?edit_requested=true"
              className="border border-secondary w-full sm:w-auto bg-secondary text-white py-3 px-14 font-bold text-lg rounded"
            >
              Apply
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default Career;
